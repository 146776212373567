import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import image1 from './../../../images/About.jpg';

const Research = () => {
  return (
    <Grid container spacing={4} sx={{ padding: '0px 20px', gap: '30px' }}>
      {/* Introduction Section */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            textAlign: 'justify',
            paddingRight: { xs: '0', md: '80px' },
            marginBottom: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: '#2c3e50',
              marginBottom: 2,
              fontSize: { xs: '1.1rem', md: '1.5rem' },
            }}
          >
            Innovation at Gaghan’s Life Sciences
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#34495e',
              lineHeight: 1.6,
              fontSize: { xs: '0.8rem', md: '1rem' },
            }}
          >
            Innovation is a driving force at Gaghan’s Life Sciences. Our dedicated research and development teams work tirelessly to explore new chemical processes and applications. This commitment to innovation ensures that we stay ahead of industry trends, anticipate customer needs, and provide cutting-edge solutions that contribute to the success of our clients.
          </Typography>
        </Box>
        <img
          src={image1}
          alt="Innovation"
          style={{
            height: '300px',
            width: '100%',
            maxWidth: '600px',
            objectFit: 'cover',
          }}
        />
      </Grid>

      {/* Text Content */}
      <Grid item xs={12}>
        <Typography
          variant="h5"
          sx={{
            color: '#2c3e50',
            marginBottom: 2,
            fontSize: { xs: '1.1rem', md: '1.5rem' },
          }}
        >
          Leading the Way in Innovation
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#34495e',
            lineHeight: 1.6,
            marginBottom: 2,
            fontSize: { xs: '0.8rem', md: '1rem' },
          }}
        >
          This commitment to innovation ensures that we stay ahead of industry trends, anticipate customer needs, and provide cutting-edge solutions that contribute to the success of our clients. Our innovative approach positions us as a trusted partner in advancing healthcare and pharmaceutical solutions.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#34495e',
            lineHeight: 1.6,
            marginBottom: 2,
            fontSize: { xs: '0.8rem', md: '1rem' },
          }}
        >
          By fostering a culture of innovation, Gaghan’s Life Sciences is able to develop breakthrough solutions that address both current and future challenges in the pharmaceutical and healthcare sectors. Our continuous research into new chemical applications empowers us to deliver the highest quality results to our clients.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: '#34495e',
            lineHeight: 1.6,
            fontSize: { xs: '0.8rem', md: '1rem' },
          }}
        >
          At Gaghan’s Life Sciences, we believe that true innovation comes from collaboration. By working closely with our clients, we tailor our solutions to meet their specific needs and help them achieve their goals. We remain committed to pushing the boundaries of what’s possible in pharmaceutical innovation.
        </Typography>
      </Grid>

      {/* Research & Development Section */}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row-reverse' },
          alignItems: 'center',
        }}
      >
        <Box sx={{ textAlign: 'justify', marginBottom: { xs: 2, md: 0 } }}>
          <Typography
            variant="h5"
            sx={{
              color: '#2c3e50',
              marginBottom: 2,
              fontSize: { xs: '1.1rem', md: '1.5rem' },
            }}
          >
            Our Commitment to Research & Development
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#34495e',
              lineHeight: 1.6,
              fontSize: { xs: '0.8rem', md: '1rem' },
            }}
          >
            Our research and development teams play a key role in driving Gaghan’s Life Sciences forward. By exploring new chemical processes and applications, we ensure our clients have access to the most effective and innovative solutions available. We invest heavily in R&D to stay ahead of market trends, ensuring that our products are always at the cutting edge.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Research;
