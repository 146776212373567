import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import image1 from "../../images/Mission.jpg";

const OurStory = () => {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        padding: { xs: "20px", sm: "40px" }, // Responsive padding
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ textAlign: "justify", paddingRight: { xs: 0, md: "40px" } }}>
          {/* <Typography variant="h4" sx={{ color: "#2c3e50", marginBottom: 2 }}>
            Leading the Way in Pharmaceutical Innovation
          </Typography> */}
          <Typography
            variant="body1"
            sx={{ color: "#34495e", lineHeight: 1.6, marginBottom: 2 }}
          >
            Gaghan’s Life Sciences is a leading pharmaceutical company that has
            been at the forefront of producing high-quality chemicals for
            various industries. We are dedicated to advancing healthcare by
            providing the most reliable, innovative, and high-quality solutions
            to our global clientele.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#34495e", lineHeight: 1.6, marginBottom: 2 }}
          >
            Our team works tirelessly to meet the diverse needs of the
            pharmaceutical, biotechnology, and healthcare sectors. By focusing
            on excellence in chemical production, we help drive the future of
            medical and scientific innovation.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#34495e", lineHeight: 1.6, marginBottom: 2 }}
          >
            Established in 2023, we have built a reputation for excellence,
            innovation, and reliability in delivering chemical solutions that
            meet the diverse needs of our global clientele.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#34495e", lineHeight: 1.6, marginBottom: 2 }}
          >
            We collaborate closely with our clients to understand their unique
            needs and challenges, tailoring our services to support their
            specific goals. This collaborative approach fosters innovation and
            ensures the success of each project.
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#34495e", lineHeight: 1.6 }}
          >
            We are committed to staying at the forefront of technological
            advancements in the pharmaceutical industry. We leverage
            cutting-edge technologies, and innovative methodologies to enhance
            the efficiency and accuracy of our services. Our investment in
            technology ensures that our clients benefit from the latest
            advancements in research and development.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={image1}
          alt="Mission"
          sx={{
            width: { xs: "100%", md: "80%" },
            height: "auto",
            borderRadius: 2,
            boxShadow: 3,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OurStory;
