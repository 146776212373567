import React from 'react';
import { Typography, Grid, useMediaQuery, Box } from '@mui/material';
import image1 from './../../../images/smallmolecule.png';
import image2 from './../../../images/peptidedesign.png';

const Aidd = () => {
  const isSmallScreen = useMediaQuery('(max-width:900px)');

  return (
    <Grid container spacing={4} sx={{ padding: '0px 20px', gap: '30px' }}>
      <Grid 
        item 
        xs={12} 
        md={12} 
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: 'center',
        }}
      >
        <Box 
          sx={{ 
            textAlign: 'justify', 
            paddingRight: isSmallScreen ? '0' : '80px',
            marginBottom: isSmallScreen ? '20px' : '0',
          }}
        >
          <Typography variant="h5" sx={{ color: '#2c3e50', marginBottom: 2 , fontSize: { xs: '1.1rem', md: '1.5rem' },}}>
            Small Molecule Discovery
          </Typography>
          <Typography variant="body1" sx={{ color: '#34495e', lineHeight: 1.6, fontSize: { xs: '0.8rem', md: '1rem' }, }}>
            We offer advanced small molecule design services leveraging cutting-edge Computer-Aided Drug Design (CADD), generative AI, and machine learning technologies. Our expertise enables rapid identification and optimization of novel 
            drug candidates by integrating molecular modeling, predictive analytics, and deep learning. Using generative AI, we create innovative molecular structures tailored to your target properties, while machine learning enhances accuracy 
            in predicting biological activity, toxicity, and pharmacokinetics. Our solutions streamline the drug discovery process, reduce development costs, and accelerate timelines, empowering pharmaceutical and biotech companies to efficiently explore chemical space and advance their therapeutic pipelines with confidence.
          </Typography>
        </Box>
        <img 
          src={image1} 
          style={{
            height: isSmallScreen ? '200px' : '300px',
            width: isSmallScreen ? '100%' : '700px',
          }} 
          alt="Small Molecule" 
        />
      </Grid>

      {/* Peptide Design */}
      <Grid 
        item 
        xs={12} 
        md={12} 
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: 'center',
        }}
      >
        <Box 
          sx={{ 
            textAlign: 'justify', 
            paddingRight: isSmallScreen ? '0' : '80px',
            marginBottom: isSmallScreen ? '20px' : '0',
          }}
        >
          <Typography variant="h5" sx={{ color: '#2c3e50', mb: 2 , fontSize: { xs: '1.1rem', md: '1.5rem' },}}>
            Peptide Design
          </Typography>
          <Typography variant="body1" sx={{ color: '#34495e', lineHeight: 1.6, mb: 2 , fontSize: { xs: '0.8rem', md: '1rem' },}}>
            We specialize in linear and cyclic peptide design services using state-of-the-art computational approaches, including molecular modeling, generative AI, and machine learning. Our expertise enables the rapid discovery and optimization of peptide-based therapeutics by predicting structure-activity relationships, enhancing stability, and improving bioavailability. Generative AI creates novel peptide sequences tailored for specific therapeutic targets, while machine learning models analyze large datasets to optimize binding affinity, stability, and pharmacokinetic properties. With a focus on innovation and precision, we help pharmaceutical and biotech companies accelerate the development of peptide therapeutics, offering solutions for diverse applications including oncology, immunology, metabolic disorders, and agri-tech.
          </Typography>
        </Box>
        <img 
          src={image2} 
          style={{
            height: isSmallScreen ? '200px' : '300px',
            width: isSmallScreen ? '100%' : '700px',
          }} 
          alt="Peptide Design" 
        />
      </Grid>
    </Grid>
  );
};

export default Aidd;
