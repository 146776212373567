import React from 'react';
import { Typography, Grid, Paper, Box } from '@mui/material';
import image1 from './../../../images/About.jpg';
import { styled } from '@mui/system';

// Styled components for enhanced look
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: '#2c3e50',
  marginBottom: theme.spacing(2),
  fontSize: { xs: '1.0rem', md: '1.5rem' },
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  color: '#34495e',
  lineHeight: 1.8,
  fontSize: { xs: '0.8rem', md: '1rem' },
}));

const ServiceCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ecf0f1',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const Process = () => {
  return (
    <Grid container spacing={4} sx={{ padding: '0px 20px', gap: '30px' }}>
      <Grid 
        item 
        xs={12} 
        sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', md: 'row' }, 
          alignItems: 'center' 
        }}
      >
        <Box sx={{ textAlign: 'justify', paddingRight: { xs: '0', md: '80px' }, mb: { xs: 2, md: 0 } }}>
          <Typography variant="h5" sx={{ color: '#2c3e50', marginBottom: 2 , fontSize: { xs: '1.0rem', md: '1.5rem' },}}>
            Enhancing Chemical Industry Operations
          </Typography>
          <Typography variant="body1" sx={{ color: '#34495e', lineHeight: 1.6, fontSize: { xs: '0.8rem', md: '1rem' }, }}>
            GAGHAN’S LIFE SCIENCES is dedicated to helping chemical industry clients achieve operational excellence and maximize their competitive advantage. With our comprehensive approach, technical expertise, and commitment to innovation, we strive to deliver customized process development solutions that drive efficiency, safety, and sustainability in the chemical manufacturing sector.
          </Typography>
        </Box>
        <img 
          src={image1} 
          alt="About Us" 
          style={{ 
            height: '300px', 
            width: '100%', 
            maxWidth: '600px', 
            objectFit: 'cover' 
          }} 
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" sx={{ color: '#2c3e50', mb: 2, fontSize: { xs: '1.0rem', md: '1.5rem' }, }}>
          Our Comprehensive Solutions
        </Typography>
        <Typography variant="body1" sx={{ color: '#34495e', lineHeight: 1.6, mb: 2 , fontSize: { xs: '0.8rem', md: '1rem' },}}>
          We offer a range of specialized services designed to optimize and innovate the chemical manufacturing process. Below are the key services we provide:
        </Typography>
      </Grid>

      <Grid container spacing={4}>
        {[
          {
            title: 'Process Development and Optimization',
            description: `We collaborate with clients to develop and optimize chemical processes, focusing on improving efficiency, increasing yields, and reducing costs. Our team works closely with clients to identify the most effective methods for enhancing production and operational performance.`,
          },
          {
            title: 'Scale-up and Pilot Plant Studies',
            description: `Our experts assist in scaling up processes from lab-scale to pilot plants. We conduct thorough studies to ensure that processes are fully optimized and can be successfully implemented at a commercial level, reducing risk and ensuring scalability.`,
          },
          {
            title: 'Process Safety and Risk Assessment',
            description: `We perform comprehensive hazard assessments and develop robust safety protocols to ensure a safe working environment for your operations. Our process safety services help you comply with regulatory requirements and protect your employees, assets, and the environment.`,
          },
          {
            title: 'Techno-economic Analysis',
            description: `Our team conducts detailed techno-economic evaluations to assess the feasibility, profitability, and sustainability of new processes or process modifications. We provide valuable insights into the economic and technical viability of your projects, helping you make informed decisions.`,
          },
          {
            title: 'Sustainability and Green Chemistry Solutions',
            description: `We integrate sustainability principles into process development, offering expertise in green chemistry practices. Our solutions focus on waste reduction, resource optimization, and the implementation of environmentally friendly technologies, ensuring that your processes contribute to a sustainable future.`,
          },
        ].map((service, index) => (
          <Grid item lg={5} xs={12} md={6} key={index} >
            <ServiceCard>
              <SectionTitle >{service.title}</SectionTitle>
              <SectionDescription>{service.description}</SectionDescription>
            </ServiceCard>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Process;
