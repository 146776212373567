import React from 'react';
import './TextHome.css';
import image1 from './../../images/frontpage.jpg'

function Home() {
  return (
    <div className="App">
      <Banner />
      <MainContent />
    </div>
  );
}

function Banner() {
  return (
    <div id="home" className="banner">
        {/* <h3>Drug Discovery as it will be</h3> */}
        <h1 className="highlight">Pioneering <span className="highlight">Chemical APIs</span> and Drug Discovery</h1>
        {/* <div className="buttons">
          <button onClick={() => document.getElementById('solutions').scrollIntoView({ behavior: 'smooth' })}>
            Learn more about Iktos
          </button>
          <button onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}>
            Contact Us
          </button>
        </div> */}
    </div>
  );
}

function MainContent() {
  return (
    <div className="main-content">
      {/* <section id="solutions" className="intro-section">
        <p>Follow a molecule's journey through Gaghans Life Science platform, from inception to drug candidate, iterating through the DMTA cycle.</p>
        <h2>Embark on the Molecular Journey</h2>
      </section>
      <section id="ai" className="ai-section">
        <h3>Innovative Molecular Design</h3>
        <h1>Generative AI Makya</h1>
        <p>Makya, our generative AI-based platform, starts the molecular journey by quickly designing novel, potent, and synthesizable molecules with the necessary characteristics for success.</p>
        <ul>
          <li>Generative AI technology for de novo drug design</li>
          <li>Focus on synthetic accessibility</li>
          <li>Multi-parameter optimization</li>
          <li>Inclusion of 3D constraints</li>
        </ul>
      </section> */}
      <section id="contact" className="contact-section">
        <h2>Contact Us</h2>
        <p>Gaghan's Life Sciences Pvt. Ltd.<br />Door No. 17-208, 1st Floor, Doolapally<br />Medchal-Malkajgiri, Hyderabad<br />Telangana, India-500055</p>
        <p>Email: info@gaghanslifesciences.com</p>
        <p>Mobile: +918985039066</p>
      </section>
    </div>
  );
}

export default Home;