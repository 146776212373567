import React from "react";
import { Container, Grid, Typography, Link, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import './Footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faLinkedinIn, faTelegramPlane } from "@fortawesome/free-brands-svg-icons"; // Social media icons

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#1A1A1A", // Dark background for a sleek look
        color: "white",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          
          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>
              GaghansLifeSciences
            </Typography>
            <Typography variant="body2" color="inherit">
              © 2024 GaghansLifeSciences. All rights reserved.
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Link  component={RouterLink} to="/services" color="inherit" underline="hover" variant="h6">
              Services
            </Link>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <Link component={RouterLink} to="/about" color="inherit" underline="hover" variant="body2">
              About Us
            </Link>
            <br />
            <Link component={RouterLink} to="/product" color="inherit" underline="hover" variant="body2">
              Products
            </Link>
            <br />
            <Link component={RouterLink} to="/contact" color="inherit" underline="hover" variant="body2">
              Contact Us
            </Link>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="h6" gutterBottom>
              Further Information
            </Typography>
            <Link href="#" color="inherit" underline="hover" variant="body2">
              Privacy Policy
            </Link>
            <br />
            <Link href="#" color="inherit" underline="hover" variant="body2">
              Terms & Conditions
            </Link>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center", marginTop: "30px" }}>
            <Typography variant="body2" gutterBottom>
              Follow Us
            </Typography>
            <Link href="#" color="inherit" underline="hover">
              <FontAwesomeIcon icon={faFacebookF} size="2x" style={{ margin: "0 10px" }} />
            </Link>
            <Link href="#" color="inherit" underline="hover">
              <FontAwesomeIcon icon={faTwitter} size="2x" style={{ margin: "0 10px" }} />
            </Link>
            <Link href="#" color="inherit" underline="hover">
              <FontAwesomeIcon icon={faLinkedinIn} size="2x" style={{ margin: "0 10px" }} />
            </Link>
            <Link href="#" color="inherit" underline="hover">
              <FontAwesomeIcon icon={faTelegramPlane} size="2x" style={{ margin: "0 10px" }} />
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;