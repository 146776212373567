import React, { useState } from "react";
import { Link, NavLink } from 'react-router-dom';
import "./Home.css";
function Homecomponent () {
  const [menuOpen, setMenuOpen] = useState(false);
    return (
      <nav>
        <Link to="/" className="title" onClick={() =>setMenuOpen(!menuOpen)}>
        <span className="gagans">GAGHANS</span> <span className="life-sciences">LIFE SCIENCES</span>
        </Link>
        <div className="menu" onClick={() => {
          setMenuOpen(!menuOpen);
        }}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={menuOpen ? "open" : ""}>
          <li onClick={() =>setMenuOpen(!menuOpen)} >
            <NavLink to="/about">Home</NavLink>
          </li>
          <li onClick={() =>setMenuOpen(!menuOpen)}>
          <NavLink to="/services">Services</NavLink>
          </li>
          {/* <li>
          <NavLink to="/product">Product</NavLink>
          </li> */}
          <li onClick={() =>setMenuOpen(!menuOpen)}>
          <NavLink to="/contact">Contact Us</NavLink>
          </li>
        </ul>
      </nav>
    );
  }
  export default Homecomponent;
  
  