import React, { useState } from "react";
import "./TextAbout.css";
import Research from "./services/Research";
import Process from "./services/Process_development";
import Aidd from "./services/AIDD";
import { Tooltip } from "bootstrap";

function Services() {
  const [selectedSection, setSelectedSection] = useState("Research and Development");

  const sections = [
   {title: 'Research and Development (R&D)', description: <div><Research /></div>},
   {title: 'Process development (PD)', description: <Process />},
   {title: 'AI Enabled Drug Discovery (AIDD)', description: <div><Aidd /></div>},
   {title: 'Organic Materials (OLED, Organic Solar cells, OFET)', description: <div></div>}
  ];

  return (
    <div className="about-container">
      <h2>Our Services</h2>
      <div className="section-buttons">
        {sections.map((section) => (
          <button
            key={section.title}
            onClick={() => setSelectedSection(section.title)}
            className={selectedSection === section.title ? 'active' : ''}
          >
            {section.title.charAt(0).toUpperCase() + section.title.slice(1)}
          </button>
        ))}
      </div>

      <div className="about-content">
      {sections
  .filter(item => item.title === selectedSection)
  .map((item, index) => ( 
    <div key={index} className="grid-item">
      <p>{item.description}</p>
    </div>
  ))}

      </div>
    </div>
  );
}

export default Services;
