import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Paper } from '@mui/material';

const App = () => {
  return (
    <Container maxWidth="lg" sx={{ paddingTop: 4 }}>
      {/* Grid container to space out elements equally */}
      <Grid container spacing={4}> {/* spacing controls the gap between elements */}
        
        {/* Top Section - Pharmaceutical Information */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Typography variant="p" sx={{ color: '#2c3e50', marginBottom: 2 }}>
            <p>Gaghan's Life Sciences Pvt. Ltd.<br />Door No. 17-208, 1st Floor, Doolapally<br />Medchal-Malkajgiri, Hyderabad<br />Telangana, India-500055</p>
        <p>Email: info@gaghanslifesciences.com</p>
        <p>Mobile: +918985039066</p>
            </Typography>
          </Paper>
        </Grid>

      </Grid>
    </Container>
  );
}

export default App;
