import React from 'react';
import { Container, Grid, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

// Styled components for enhanced look
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: '#2c3e50',
  marginBottom: theme.spacing(2),
  fontSize: '1.8rem',
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  color: '#34495e',
  lineHeight: 1.8,
  fontSize: '1rem',
}));

const ServiceCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#ecf0f1',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const ChemicalProcess = () => {
  return (
    <Container  sx={{ paddingTop: 4, width: '100%' }}>
      <Typography variant="h3" sx={{ textAlign: 'center', color: '#2c3e50', marginBottom: 4 }}>
         Enhancing Chemical Industry Operations
      </Typography>

      <Box sx={{ marginBottom: 6 }}>
        <SectionDescription>
          GAGHAN’S LIFE SCIENCES is dedicated to helping chemical industry clients achieve operational excellence and maximize their competitive advantage. With our comprehensive approach, technical expertise, and commitment to innovation, we strive to deliver customized process development solutions that drive efficiency, safety, and sustainability in the chemical manufacturing sector.
        </SectionDescription>
      </Box>

      <Grid container spacing={4}>
        {/* Process Development and Optimization */}
        <Grid item xs={12} md={6}>
          <ServiceCard>
            <SectionTitle>Process Development and Optimization</SectionTitle>
            <SectionDescription>
              We collaborate with clients to develop and optimize chemical processes, focusing on improving efficiency, increasing yields, and reducing costs. Our team works closely with clients to identify the most effective methods for enhancing production and operational performance.
            </SectionDescription>
          </ServiceCard>
        </Grid>

        {/* Scale-up and Pilot Plant Studies */}
        <Grid item xs={12} md={6}>
          <ServiceCard>
            <SectionTitle>Scale-up and Pilot Plant Studies</SectionTitle>
            <SectionDescription>
              Our experts assist in scaling up processes from lab-scale to pilot plants. We conduct thorough studies to ensure that processes are fully optimized and can be successfully implemented at a commercial level, reducing risk and ensuring scalability.
            </SectionDescription>
          </ServiceCard>
        </Grid>

        {/* Process Safety and Risk Assessment */}
        <Grid item xs={12} md={6}>
          <ServiceCard>
            <SectionTitle>Process Safety and Risk Assessment</SectionTitle>
            <SectionDescription>
              We perform comprehensive hazard assessments and develop robust safety protocols to ensure a safe working environment for your operations. Our process safety services help you comply with regulatory requirements and protect your employees, assets, and the environment.
            </SectionDescription>
          </ServiceCard>
        </Grid>

        {/* Techno-economic Analysis */}
        <Grid item xs={12} md={6}>
          <ServiceCard>
            <SectionTitle>Techno-economic Analysis</SectionTitle>
            <SectionDescription>
              Our team conducts detailed techno-economic evaluations to assess the feasibility, profitability, and sustainability of new processes or process modifications. We provide valuable insights into the economic and technical viability of your projects, helping you make informed decisions.
            </SectionDescription>
          </ServiceCard>
        </Grid>

        {/* Sustainability and Green Chemistry Solutions */}
        <Grid item xs={12} md={6}>
          <ServiceCard>
            <SectionTitle>Sustainability and Green Chemistry Solutions</SectionTitle>
            <SectionDescription>
              We integrate sustainability principles into process development, offering expertise in green chemistry practices. Our solutions focus on waste reduction, resource optimization, and the implementation of environmentally friendly technologies, ensuring that your processes contribute to a sustainable future.
            </SectionDescription>
          </ServiceCard>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ChemicalProcess;
