import React, { useState } from "react";
import about from "../../images/About.jpg";
import OurStory from "./ourstory";
import "./TextAbout.css";
import { Typography } from "@mui/material";

function About() {
  const [selectedSection, setSelectedSection] = useState("about");

  const sections = {
    about: [
      {
        title: "Delivering the greatest possible impact through mRNA medicines",
        description:
          <OurStory />,
        
      }
      
    ],
    mission: [
      {
        title: "Our Mission",
        description: <Typography variant="body1" sx={{ color: '#34495e', lineHeight: 1.6 }}>Our mission is to be a trusted partner in the chemical industry by providing innovative,
            sustainable, and high-performance solutions to our customers. We are committed to advancing technology, ensuring environmental responsibility, and 
            contributing to the success of our clients through the quality of our products and services.</Typography>
      },
    ],
    vision: [
      {
        title: "Our Vision",
        description: <Typography variant="body1" sx={{ color: '#34495e', lineHeight: 1.6 }}>To be a competitive leader in the chemical sector locally and 
        globally by offering top-notch products, services, and values.</Typography>
      },
    ],
  };

  return (
    <div className="about-container">
      <h2>About Us</h2>
      <div className="section-buttons">
        {Object.keys(sections).map((section) => (
          <button
            key={section}
            onClick={() => setSelectedSection(section)}
            className={selectedSection === section ? 'active' : ''}
          >
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </button>
        ))}
      </div>

      <div className="about-content">
        {sections[selectedSection].map((item, index) => (
          <div key={index} className="grid-item">
                <p>{item.description}</p>
              </div>
          
            
        ))}
      </div>
    </div>
  );
}

export default About;
